import * as React from "react"
import {graphql, navigate} from "gatsby"
import {useEffect, useState} from "react";
import Layout from "../components/layout"
import Seo from "../components/seo"
import "./mystyles.scss"
import DatesList from "../components/dasboard/dates_list";
import {isLoggedIn} from "../utils/auth.service";
import authHeader from "../utils/auth-header";
import LoadingSpinner from "../components/loading-spinner";
import {useTranslation} from "gatsby-plugin-react-i18next";


const Dashboard = () => {

    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [schedule, setSchedule] = useState([]);


    //@todo to one function
    useEffect(() => {

        if (!isLoggedIn()) {
            return navigate('/');
        }

        loadScheduleFromApi();


    }, [])


    const loadScheduleFromApi = () => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
        };

        setLoading(true)

        // get data from GitHub api
        fetch(process.env.WASHCAR_API_URL + "/business/dashboard", requestOptions)
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {
                setLoading(false)
                setSchedule(resultData.data.schedule);
            })
    }

    return (
        <Layout>

            <Seo title={t('calendar')}/>
            <h1 className="title">{t('calendar')}</h1>


            {loading ? <div className="pos-center"><LoadingSpinner/></div> : <div>
                <DatesList schedule={schedule}/>
            </div>}


        </Layout>
    )
}

export default Dashboard

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
