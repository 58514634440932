import React from 'react';
import ScheduleDateOrders from "../booking/date-orders";
import {Link} from "gatsby";
import {useTranslation} from "gatsby-plugin-react-i18next";


const DatesList = (props) => {

    const {t} = useTranslation();

    return (

        props.schedule.map(day => (
            <div key={day.date} className="card mb-4">

                <header className="card-header">
                    <p className="card-header-title">
                        {day.date_pretty} ({day.day_label})
                    </p>

                    <Link className="button is-link mr-2 mt-2 mb-2" state={{ fromFeed: day.date }}   to={`/booking`}>{t('book_now')}</Link>


                </header>

                <div className="card-content">

                    <div className="content">
                        {typeof day.orders !== 'undefined' ? <ScheduleDateOrders dateOrders={day.orders} />: null}
                    </div>

                </div>





            </div>
        ))


    );
};

export default DatesList;




/*
    const navigateToBooking = () => {

        navigate(
            "/booking",
            {
                state: { date : '12222'  },
            }
        )

    }
 */

